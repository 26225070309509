import React from 'react';

function Home() {
  return (
    <div className="topDiv">
			<div className="banner">
				<center>
					<div className="mb-5 mt-2">
						<img src="logotext_new.jpeg" className="w-50 mb-3" /> <br/>
						<h2 className="sub-heading">A SOCIAL NETWORK FOR SOCIAL ACTION</h2>
					</div>
					<hr/>
				</center>
				<div className="mt-5">
					<center>
						<a href="/download-page" className="btn btn-dark text">DOWNLOAD</a>
					</center>
				</div>
				<div className="mt-4 w-75 mx-auto text">
					<div className="card border-light bg-transparent">
						<div className="card-body py-5">
							<div className="" style={{ width: '100%' }}>
								<span className="green ">[1] What is Portal?</span> 
								<br/>
								<br/>
								Portal is a social network for social <span className="green">action</span>. A Social game that unlocks the hidden potential of the individual and maximizes the output of the collective.
							</div>
						</div>
					</div>
				</div>

				<a href="#sec-2">
					<div className="scroll-down"></div>
				</a>
				<div className="modal fade" id="messageModal2" tabIndex="-1" aria-labelledby="messageModalLabe1" aria-hidden="true">
					<div className="modal-dialog modal-sm modal-dialog-centered ">
						<div className="modal-content text">
							<div className="modal-header w-100 mb-0">
								<h5 className="modal-title text-white ml-0" id="exampleModalLabel">Success Message</h5>
								<button type="button" className="btn-close btn-close-white mr-0" data-bs-dismiss="modal" aria-label="Close"></button>
							</div>
							<div className="modal-body p-0">
								<div className="modal-content animate">
									<p className="m-5 text-white">Your Details have been successfully registered.</p>
									<center>
										<button type="button" id="cancelBtn" className="cancelBtn w-100" data-bs-dismiss="modal">OK</button>
									</center>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="container-fluid mb-2 px-5 mt-3" id="sec-2">
				<hr/>
				<div className="row my-4">
					<div className="col-md-3">
						<h3 className="title mb-4   heading">The Problem</h3>
					</div>
					<div className="col-md-9 explanation text text">
						<div className="row">
							<div className="container col-lg-3 p-2">
								<center>
									<span>*</span>
									<br/>
									<br/>
									<span className="green">4.5b+ online</span> 
									<br/> 
									<br/>
									<span className="red my-4">Yet we all feel alone</span>
								</center>
							</div>
							<div className="container col-lg-3 p-2">
								<center>
									<span>*</span>
									<br/>
									<br/>
									<span className="green">Information Surplus </span>
									<br/>
									<br/>
									<span className="red my-4"> Yet, Knowledge Poor</span>
								</center>
							</div>
							<div className="container col-lg-3 p-2">
								<center>
									<span>*</span>
									<br/>
									<br/>
									<span className="green">Trillions of Value created </span>
									<br/>
									<br/>
									<span className="red my-4"> Yet we are slaves to the present</span>
								</center>
							</div>
						</div>
					</div>
				</div>
				<hr/>
				<div className="row my-4">
					<div className="col-md-3">
						<h3 className="title mb-4   heading">// The Premise</h3>
					</div>
					<div className="col-md-9 explanation text">
						<div className="" style={{ width: '100%' }}>
							<span className="green ">[1] What is Portal?</span> 
							<br/>
							<br/>
							Portal is a social network for social <span className="green">action</span>. A Social game that unlocks the hidden potential of the individual and maximizes the output of the collective.
							<br/> 
							<br/>
							<span className="green ">[2] Why?</span>
							<br/> 
							<br/>In a world, where all people can do is complain about <span className="red">problems</span> they face. We aim to build better competitive and collaborative game systems that <span className="green">solve</span> problems rather than “raise awareness”.
						</div>
					</div>
				</div>
				<hr/>
				<div className="row my-4">
					<div className="col-md-3">
						<h3 className="title mb-4   heading">// The Story</h3>
					</div>
					<div className="col-md-9 explanation text">
						<div className="" style={{ width: '100%' }}><span className="green ">[1] Individual Story : </span>
							<br/> 
							<br/>
							Each individual lives their own story. All of us have a <span className="red">past</span>,A Present and a <span className="green">Future</span> that we work towards.
							<br/> 
							<br/>
							<span className="green ">[2] Collective Story :</span>
							<br/> 
							<br/>Each Civilization has a collective story they hold as an <span className="green">IDeal</span>,towards which they strive. Stories of past civilizations still have a major influence over art, <span className="green">culture</span> and <span className="red">politics</span>.
						</div>
					</div>
				</div>
				<hr/>
				<div className="row my-4">
					<div className="col-md-3">
						<h3 className="title mb-4   heading">// The Game</h3>
					</div>
					<div className="col-md-9 explanation text">
						<div className="" style={{ width: '100%' }}><span className="green ">[1] COMPETITIVE GAMES:</span>
							<br/> 
							<br/>
							Every interaction between humans is a game. 
							<br/>
							Humans compete to climb social hierarchies, some do it for the <span className="red">Dopamine</span> and <span className="green">Serotonin</span>.
							<br/>
							Games are a fundamental aspect of life. Just as we compete with each other we also play games with our future self. 
							<br/>
							<br/>
							<a href="https://en.wikipedia.org/wiki/Non-cooperative_game_theory" target="_blank" style={{ color: 'white' }}> More info -&gt;</a>
							<br/> 
							<br/>
							<span className="green ">[2] COLLABORATIVE GAMES :</span>
							<br/> 
							<br/>These are games played by collective entities toward an agreed upon Goal
							<br/>
							<span className="green">Scenius</span> (def.): 
							<br/>
							The ability, intuition and creative intelligence of a community. Great things happen when people come together. Examples of scenius include: The Bell Laboratories, The Traitorous Eight, and The Paypal Mafia. 
							<br/>
							<br/>
							<a href="https://en.wikipedia.org/wiki/Cooperative_game_theory" target="_blank" style={{ color: 'white' }}> More info -&gt;</a>
						</div>
					</div>
				</div>
				<hr/>
				<div className="row my-4">
					<div className="col-md-3">
						<h3 className="title mb-4   heading">// The Features</h3>
					</div>
					<div className="col-md-9 explanation text">
						<div className="" style={{ width: '100%' }}>THIS PLATFORM SHARES ELEMENTS OF EXISTING SOCIAL NETWORKS. SUCH AS 
							<br/>POSTS -&gt; <span className="green">RANTS</span>
							<br/>FOLLOW -&gt; <span className="green">ORBIT</span>
							<br/>
							<br/>
							THIS PLATFORM ALSO INTRODUCES NEW FEATURES THAT TURNS ASPECT OF LIFE INTO A GAME. THE <span className="red">ANTI</span>-METAVERSE 
							<br/>
							<br/>
							<span className="green ">[1] CHARACTER STATS</span>: Using Character Stats one can derive <span className="green">insights</span> into the current state of their being. 
							<br/>
							WHY: A properly executed character stat can act as a 3rd eye for individuals. It will allow you to hold an objective view of your state of being and can direct your steps to mitigate failure or strengthen weaknesses. 
							<br/>
							<br/>
							<span className="green ">[2] DESTINY</span>: Destiny is the formulation of the hope that you have for your future self. The perfected future state ofo your being. your own <span className="green">heaven</span>
							<br/>
							A hope for a better future is the fundamental driving force of innovation, change and human progress. This hope that the future will be better than the past, and present is the reason why individuals can endure so much suffering.
							<br/>
							<br/>
							<span className="green ">[3] LifeLog</span>: Life Logs are your plans for the present and immediate future. By constantly manipulating the present, you command your future into existence. <span className="green">Future = Past + Present</span>.
							<br/>
							Why: Defining your version of heaven is not enough for the individual to reach this state. It is not enough to have an accurate representation of your state of being. You also need the tools that get you from here (The present) to there (the future).
							<br/>
							Life logs are tools to manage your path and trajectory so that you can reach your version of heaven. It is also a competitive game theoretic tool that allows you to get better results by competing with your friends and your past self.
							<br/>
							<br/>
							<span className="green ">[4] Judgement</span>: Judgment between <span className="green">Heaven</span> and <span className="red">Hell</span>, forces re-evaluation, and reinforces the right decisions that pushed you further closer to your definition of heaven.<br/><br/>This platform also introduces the features to build <span className="green">replicators</span> and systems of <span className="green">knowledge</span> that use collaborative game systems to find solutions to societal <span className="red">problems</span>
							<br/>
							<br/>
							<span className="green ">[5] quest</span>: A Quest is a collective journey in search of explanation texts and solutions. With the spirit of the <span className="green">enlightenment</span>, A brings together individual contributors, and financial contributors in order to find solutions that will lead us into the <span className="green">future</span>.
							<br/>
							Why:  link to the game
							<br/>
							<br/>
							<span className="green ">[6] Syndicates</span>: Is a collection of Individual contributors that work toward a common goal. By dividing the collective into smaller syndicates, the platform can ensure <span className="red">anti</span>-dogma and heterodoxy in the pursuit of <span className="green">enlightenment</span>.
							<br/>
							Why:  link to the game
							<br/>
							<br/>
						</div>
					</div>
				</div>
				<hr/>
				<div className="row my-4">
					<div className="col-md-3">
						<h3 className="title mb-4   heading">// Assets under development</h3>
					</div>
					<div className="col-md-9 explanation text">
						<div className="" style={{ width: '100%' }}><span className="green ">[1] app/web development</span> 
							<br/>
							<br/>
							<span className="green ">[2] GAME PROGRESSION AI</span>:
							<br/>
							AI to manage the character stats that <span className="green">increase</span>/<span className="red">decrease</span> depending on the player
							<br/>
							<br/>
							<span className="green ">[3] supernatural aid</span>: 
							<br/>
							adapted from the philosophical template of <span className="green">ther hero's journey</span>.the supernatural aid exists as a helper in the form of an ai assistant to help the hero through his journey
							<br/>
							<br/>
							<span className="green ">[4] philosophy</span>:
							<br/>
							<br/>
							<div className="row">
								<div className="col-md-6">
									<center>substructures of reality i : stories 
										<br/>
										<a href="https://medium.com/@alpha137tech/substructures-of-reality-stories-4bc989a2a8a9" target="_blank" style={{ color: 'white' }}> More info -&gt;</a>
									</center>
								</div>
								<div className="col-md-6">
									<center>substructures of reality ii : games 
										<br/>
										<a href="https://medium.com/@alpha137tech/substructures-of-reality-games-d16ed16a8c8f" target="_blank" style={{ color: 'white' }}> More info -&gt;</a>
									</center>
								</div>
							</div>
						</div>
					</div>
				</div>
				<hr/>
				<div className="mt-5">
					<center className="huge-font mt-5 text">
						<h5 className="mt-5"><span className="green">memento</span> <span className="red">mori </span>remember that you are going to <span className="red">die</span></h5>
					</center>
				</div>
				<hr/>
			</div>
		</div>
  );
}

export default Home;
