import React, { useState } from 'react';
import firebase from './firebase';

import { getFirestore, collection, addDoc } from 'firebase/firestore'; 

function Download() {
    const [formState, setFormState] = useState({
        email: '',
        username: '',
        identity: 'Choose Identity',
      });
      const [successMessage, setSuccessMessage] = useState(null);
    
      const handleFormChange = (e) => {
        const { name, value } = e.target;
        setFormState({ ...formState, [name]: value });
      };
    
      const handleSubmit = async (e) => {
        e.preventDefault();
    
        // Basic form validation
        if (!formState.email || !formState.username || formState.identity === 'Choose Identity') {
          // Display an error message
          setSuccessMessage('Please fill out all required fields and select your identity.');
          return;
        }
    
        // Form submission logic (e.g., sending data to Firestore)
        try {
            const db = getFirestore(firebase); 
            const collectionRef = collection(db, 'new-user-register');
            const data = {
                device: "ios",
                email: formState.email,
                name: formState.username,
                identity: formState.identity,
            };

            await addDoc(collectionRef,data);
            setSuccessMessage('Your details have been successfully registered.');
            console.log('Success message set:', successMessage);
        } catch(error) {
            console.error('Error adding document to Firestore: ', error);
        }
    
        // Display a success message
        setSuccessMessage('Your details have been successfully registered.');
      };
    
      const resetForm = () => {
        setFormState({
          email: '',
          username: '',
          identity: 'Choose Identity',
        });
        setSuccessMessage(null);
      };
  
    return (
    <div className="topDiv">
        <div className="banner d-flex flex-column justify-content-center align-items-center">
            <div className="row w-75 h-100">
                <div className="col-lg-4 px-4 h-100">
                    <div className="card border-light bg-transparent">
                        <center>
                            <div className="py-5">
                                <i className="mdi mdi-android" style={{ fontSize: '100px' }}></i>
                            </div>
                            <div className="card-body py-5">
                                <a href="https://play.google.com/store/apps/details?id=com.xandria.tech" target="_blank" className="btn btn-outline-dark btn-block w-100" style={{ color: 'rgb(245, 245, 245)' }} >Download</a>
                            </div>
                        </center>
                    </div>
                </div>
                <div className="col-lg-4 px-4 h-100">
                    <div className="card border-light bg-transparent">
                        <center>
                            <div className="py-5">
                                <i className="mdi mdi-apple" style={{ fontSize: '100px' }}></i>
                            </div>
                            <div className="card-body py-5">
                                <button type="button" className="btn btn-outline-dark btn-block w-100" data-bs-toggle="modal" data-bs-target="#mainRegistrationModal" style={{ color: 'rgb(245, 245, 245)' }}>Register for Updates</button>
                            </div>
                        </center>
                    </div>
                </div>
                <div className="col-lg-4 px-4 h-100">
                    <div className="card border-light bg-transparent">
                        <center>
                            <div className="py-5">
                                <i className="mdi mdi-web" style={{ fontSize: '100px' }}></i>
                            </div>
                            <div className="card-body py-5">
                                <a href="https://portalsocial.pythonanywhere.com/"  className="btn btn-outline-dark btn-block w-100" target="_blank" style={{ color: 'rgb(245, 245, 245)' }}>Go to Now</a>
                            </div>
                        </center>
                    </div>
                </div>
                <center>
                    <a href="/" className="btn btn-dark text mt-5 w-75">View Feature List</a>
                </center>
            </div>
        </div>
        <div className="modal fade" id="mainRegistrationModal" tabIndex="-1" aria-labelledby="mainRegistrationModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-lg mx-auto">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title heading" id="mainRegistrationModalLabel">registration form</h5>
                        <button type="button" className="btn-close btn-close-white" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <div className="mb-3">
                            <label htmlFor="formEmail" className="form-label text">Email address</label>
                            <input type="email" required name="email" className="form-control w-75" id="formEmail" placeholder="name@example.com" style={{ background: 'black', color: 'white' }} value={formState.email} onChange={handleFormChange} />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="formUsername" className="form-label text">user name</label>
                            <input type="text"  className="form-control w-75" id="formUsername" placeholder="" name="username" required value={formState.username} onChange={handleFormChange} style={{ background: 'black', color: 'white' }} />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="formUsername" className="form-label text">I am ...</label>
                            <select className="form-select form-select-sm text-white form-control w-75" required name="identity" value={formState.identity} onChange={handleFormChange} aria-label=".form-select-sm" style={{ background: 'black', color: 'white', cursor: 'pointer' }}>
                                <option value="Choose Identity">Click to Choose Identity</option>
                                <option value="a student">a student</option>
                                <option value="an executive">an executive</option>
                                <option value="an investor">an investor</option>
                                <option value="a Sigma">a Sigma</option>
                            </select>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="submit" className="btn btn-outline-success" data-bs-toggle="modal" data-bs-target="#messageModal2" data-bs-dismiss="modal" onClick={handleSubmit}>Register</button>
                        <button type="button" className="btn btn-outline-danger" data-bs-dismiss="modal">Close</button>
                    </div>
                    
                </div>
            </div>
        </div>
        <div className="modal fade" id="messageModal2" tabIndex="-1" aria-labelledby="messageModalLabe1" aria-hidden="true">
            <div className="modal-dialog modal-sm modal-dialog-centered ">
                <div className="modal-content text">
                    <div className="modal-header w-100 mb-0">
                        <h5 className="modal-title text-white ml-0" id="exampleModalLabel">Success Message</h5>
                        <button type="button" className="btn-close btn-close-white mr-0" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body p-0">
                        <div className="modal-content animate">
                            {successMessage ? (
                            <p className="m-5 text-white">{successMessage}</p>
                            ) : null}
                            <center>
                                <button type="button" id="cancelBtn" className="cancelBtn w-100" data-bs-dismiss="modal">OK</button>
                            </center>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  );
}

export default Download;
